import {
    IMAGE_PLACEHOLDERS,
    IMAGE_TYPE_PRODUCT_256w_256h,
    IMAGE_TYPE_PRODUCT_512w_512h,
    PRODUCT_IMAGE_TYPES,
} from '@types/Image';
import { TYPE_COLOR_MAP } from '@types/Badge';

import { getDiscount, getDiscountBadge } from '@assets/discount';
import { isCurrentDateBetweenDates } from '@assets/date';

import DataLayerProduct from '@models/Analytics/DataLayerProduct';
import DateHelper from '@models/DateHelper/DateHelper';

const EMPTY_VALUE = '-';
const WEB_DISTRIBUTION = 'web';
const PROMO_LABEL_DATE_FORMAT = 'MMM d, yyyy, h:mm:ss a';

export const mapProductToDataLayerProduct = ({ product, index }) => {
    const {
        sku,
        badges,
        brandName,
        name,
        fason,
        categories,
        sizes,
        price: {
            promotional: { amount: promotionalPrice },
            regular: { amount: regularPrice },
        },
    } = product;

    const badgesMapped = Object.values(badges)
        .map(({ label }) => label)
        .join('/');

    const position = index + 1;

    let category = '';

    if (categories?.[0] && !Array.isArray(categories?.[0])) {
        category = categories[0].split('/').slice(1).join('/');
    }

    let allProductVariants = '';
    let variantsInStockSizes = '';
    let sizesInStockCount = '';

    if (sizes) {
        allProductVariants = sizes.join(',');
        variantsInStockSizes = sizes.join(',');
        sizesInStockCount = sizes.length;
    }

    return DataLayerProduct.buildResult({
        id: sku,
        position,
        price: promotionalPrice,
        badges: badgesMapped,
        brand: brandName,
        brandName,
        name,
        fason,
        category,
        allProductVariants,
        variantsInStockSizes,
        sizesInStockCount,
        isOneSize: sizesInStockCount.count > 1 ? true : '',
        promotionalPrice: `${promotionalPrice}-${promotionalPrice}`,
        regularPrice: `${regularPrice}-${regularPrice}`,
    });
};

const getAttributeValue = (attributes = {}, name = '') => {
    return attributes[name] || null;
};

const getArrayOfValues = ({ attributes, code }) => {
    const attributeValues = getAttributeValue(attributes, code) || [];

    return attributeValues.map(item => (item === EMPTY_VALUE ? null : item));
};

export const getValidPromoLabels = attributes => {
    const startDates = getArrayOfValues({
        attributes,
        code: 'akcja_start_date',
    });
    const endDates = getArrayOfValues({ attributes, code: 'akcja_end_date' });
    const channels = getArrayOfValues({ attributes, code: 'akcja_kanal' });
    const contents = getArrayOfValues({
        attributes,
        code: 'akcja_wlepka_tresc',
    });
    const colors = getArrayOfValues({ attributes, code: 'akcja_wlepka_kolor' });

    const promoLabels = [];

    if (
        !colors.length ||
        !channels.length ||
        !contents.length ||
        !startDates.length ||
        !endDates.length
    ) {
        return promoLabels;
    }

    startDates.forEach((_, index) => {
        const startDate = startDates[index];
        const endDate = endDates[index];
        const channel = (channels[index] || '')
            .split(',')
            .map(item => item.trim());
        const content = contents[index];
        const color = colors[index];

        if (
            !startDate ||
            !endDate ||
            !channel.includes(WEB_DISTRIBUTION) ||
            !content ||
            !color
        ) {
            return;
        }

        promoLabels.push({
            startDate,
            endDate,
            channel,
            formattedContent: content,
            bgColor: color,
            textColor: '#fff',
        });
    });

    return promoLabels;
};

export const getPromoLabel = (attributes, timezone) => {
    try {
        const promoLabels = getValidPromoLabels(attributes);

        const dateHelper = new DateHelper(timezone, PROMO_LABEL_DATE_FORMAT);

        return promoLabels.find(({ startDate, endDate }) =>
            isCurrentDateBetweenDates({ dateHelper, startDate, endDate })
        );
    } catch {
        return null;
    }
};

export const mapProduct = ({
    product,
    currency,
    $createProductPath,
    isOmnibusStrictEnabled,
    isDiscountEnabled,
    timezone,
    isRecommendationStickersTestEnabled,
    $t,
}) => {
    const {
        title = null,
        brand = '',
        link = null,
        description,
        itemId = null,
        price = null,
        salePrice = null,
        imageLink = IMAGE_PLACEHOLDERS[IMAGE_TYPE_PRODUCT_256w_256h].url,
        category = '',
        attributes,
        sizes = [],
        additionalImageLinks = [],
    } = product;

    const { width, height } = PRODUCT_IMAGE_TYPES[IMAGE_TYPE_PRODUCT_256w_256h];

    const imagesLinks = [imageLink];

    if (additionalImageLinks.length && additionalImageLinks[0]) {
        imagesLinks.push(additionalImageLinks[0]);
    }

    const images = [];

    imagesLinks.forEach(imageUrl => {
        images.push({
            [IMAGE_TYPE_PRODUCT_512w_512h]: {
                url: imageUrl,
                alt: description,
                width,
                height,
                sources: {},
            },
            [IMAGE_TYPE_PRODUCT_256w_256h]: {
                url: imageUrl,
                alt: description,
                width,
                height,
                sources: {},
            },
        });
    });

    const priceFormatted = getAttributeValue(attributes, 'price_formatted');

    const salePriceFormatted = getAttributeValue(
        attributes,
        'sale_price_formatted'
    );

    const omnibusPrice =
        parseInt(getAttributeValue(attributes, 'omnibus_price_amount'), 10) ||
        null;

    const omnibusFormattedValue = getAttributeValue(
        attributes,
        'omnibus_price_formatted'
    );

    const omnibusPriceFormatted = omnibusPrice ? omnibusFormattedValue : null;

    const omnibusDiscountSynerise = parseInt(
        getAttributeValue(attributes, 'omnibus_discount'),
        10
    );

    const discountSynerise = parseInt(
        getAttributeValue(attributes, 'discount'),
        10
    );

    const quantity = getAttributeValue(attributes, 'quantity');

    const urlKey = getAttributeValue(attributes, 'url_key');

    const categoryMapped = category
        .split('>')
        .map(item => item.trim())
        .filter(item => item.length)
        .join('/');

    const badgesAttribute = [
        getAttributeValue(attributes, 'etykieta') || [],
    ].flat();

    const priceAmount = price?.value || null;
    const salePriceAmount = salePrice?.value || null;

    const regularPriceAmount = parseFloat(priceAmount) || 0;
    const finalPriceAmount = parseFloat(salePriceAmount) || 0;
    const omnibusPriceAmount = parseFloat(omnibusPrice) || 0;

    const hasOmnibus = omnibusPriceFormatted && omnibusPriceAmount;

    const promoSticker =
        (hasOmnibus || !isOmnibusStrictEnabled) &&
        isRecommendationStickersTestEnabled
            ? getPromoLabel(attributes, timezone)
            : null;

    // eslint-disable-next-line max-len
    // @TODO - remove getDiscount calculation when synerise returns discount attribute
    const discountRegular = parseInt(
        discountSynerise || getDiscount(regularPriceAmount, finalPriceAmount),
        10
    );

    let discount;

    if (isOmnibusStrictEnabled) {
        discount = hasOmnibus ? omnibusDiscountSynerise ?? null : null;
    } else {
        discount = discountRegular ?? null;
    }

    if (!isDiscountEnabled) {
        discount = null;
    }

    const mappedBadges = badgesAttribute.map(badge => [
        badge,
        {
            code: badge,
            label: badge,
        },
    ]);

    if (discount !== null) {
        const { badgeType, badgeContent } = getDiscountBadge({
            isOmnibusStrict: isOmnibusStrictEnabled,
            omnibusAmount: omnibusPriceAmount,
            regularAmount: regularPriceAmount,
            promotionalAmount: finalPriceAmount,
            discount,
            $t,
        });

        if (badgeType) {
            mappedBadges.unshift([
                'discount',
                {
                    code: 'discount',
                    label: badgeContent,
                    color: TYPE_COLOR_MAP[badgeType],
                },
            ]);
        }
    }

    const badges = Object.fromEntries(mappedBadges.slice(0, 2));

    const fason = getAttributeValue(attributes, 'fason');

    if (
        !priceAmount ||
        !salePriceAmount ||
        !priceFormatted ||
        !salePriceFormatted ||
        !urlKey ||
        !link ||
        !title ||
        !itemId
    ) {
        return null;
    }

    const query = link
        .split('?')
        .pop()
        .split('&')
        .reduce((acc, param) => {
            const [name, value] = param.split('=');

            acc[name] = value;

            return acc;
        }, {});

    return {
        name: title.replace('  ', ' '),
        nameDisplay: title.replace(brand, '').replace('  ', ' '),
        sku: itemId,
        brandName: brand,
        badges,
        fason,
        quantity,
        categories: [categoryMapped].filter(item => item),
        currency,
        url: $createProductPath(urlKey, query),
        pictureWidth: width,
        pictureHeight: height,
        images,
        sizes,
        price: {
            discount: discount > 0 ? String(discount) : null,
            regular: {
                amount: regularPriceAmount,
                formatted: priceFormatted,
            },
            promotional: {
                amount: finalPriceAmount,
                formatted: salePriceFormatted,
            },
            omnibus: {
                amount: omnibusPriceAmount,
                formatted: omnibusPriceFormatted,
            },
        },
        variants: {},
        promoSticker,
    };
};

export const matchBestCategory = (categories, ids) => {
    const categoriesIds = categories.map(({ id }) => id);

    const category = ids.reduce(
        (bestCategory, currentId) => {
            const depth = categoriesIds.indexOf(currentId);

            if (depth > bestCategory.depth) {
                bestCategory.id = currentId;
                bestCategory.depth = depth;
            }

            return bestCategory;
        },
        {
            id: null,
            depth: -1,
        }
    );

    return category.id;
};
