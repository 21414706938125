<template>
    <Observer
        v-if="displayContainer && !disabled"
        :options="CONTAINER_OBSERVER_OPTIONS"
        :observe-once="true"
        :class="getClass"
        class="slider-container"
        @intersect="displayContent($event)"
    >
        <ContainerContent v-if="isDisplayed" class="recommended-slider-content">
            <Loader v-if="!isLoaded" />
            <RecommendedProductsDataProvider
                :recommendation-type="recommendationType"
                :category-breadcrumbs="categoryBreadcrumbs"
                :product-sku="productSku"
                :product-brand="productBrand"
                :custom-campaign-id="customCampaignId"
                :limit="limit"
                :size="size"
                @loaded="onLoadedProducts($event)"
                @error="handleError()"
            >
                <component
                    :is="productsSliderComponent"
                    v-if="isLoaded"
                    :products="productsLoaded"
                    :slider-title="sliderTitle"
                    :has-scrollbar="hasScrollbar"
                    :slider-title-theme="sliderTitleTheme"
                    :action-field="recommendationType"
                    @navigation-button-click="
                        emitNavigationButtonClickEvent($event)
                    "
                />
            </RecommendedProductsDataProvider>
        </ContainerContent>
    </Observer>
</template>

<script>
import { DEFAULT_DEBOUNCE_TIME } from '@configs/recommendations';

import { SYNERISE_RECOMMENDATION_TYPES } from '@types/Synerise';

import {
    PRODUCT_RECOMMENDATION_SLIDER_VIEW,
    PRODUCT_RECOMMENDATION_SLIDER_ARROW_CLICK,
} from '@analytics-module/modules/product/types/Events';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';
import { LABELS as GLOBAL_LABELS } from '@analytics-types/Labels';

import { debounceAggregate } from '@assets/debounce-aggregate';
import { checkIfExistsInValuesMap } from '@assets/props';

import LoadProducts from '@mixins/LoadProducts';

import Loader from '@atoms/Loader/Loader';
import Observer from '@atoms/Observer/Observer';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

const ERROR = 'error';

export default {
    name: 'RecommendedProductsSliderWrapper',

    components: {
        Observer,
        Loader,
        ContainerContent,

        RecommendedProductsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "recommended-products-data-provider" */
                '@molecules/RecommendedProductsDataProvider/RecommendedProductsDataProvider'
            ),
        }),
    },

    mixins: [LoadProducts],

    props: {
        recommendationType: {
            type: String,
            required: true,
            validator: checkIfExistsInValuesMap(SYNERISE_RECOMMENDATION_TYPES),
        },

        categoryBreadcrumbs: {
            type: Array,
            default: () => [],
        },

        productSku: {
            type: [String, Array],
            default: '',
        },

        productBrand: {
            type: String,
            default: '',
        },

        container: {
            type: String,
            default: '',
        },

        sliderTitle: {
            type: String,
            default: '',
        },

        sliderTitleTheme: {
            type: String,
            default: 'h3',
        },

        hasScrollbar: {
            type: Boolean,
            default: true,
        },

        customCampaignId: {
            type: String,
            default: '',
        },

        limit: {
            type: Number,
            default: 0,
        },

        size: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isDisplayed: false,
            displayContainer: true,
        };
    },

    computed: {
        getClass() {
            const classes = [this.container];

            if (this.hasScrollbar) {
                classes.push('with-scrollbar');
            }

            return classes;
        },

        isLoaded() {
            return this.productsLoaded.length;
        },
    },

    beforeCreate() {
        this.CONTAINER_OBSERVER_OPTIONS = {
            root: null,
            threshold: 0,
            rootMargin: '0px 0px 200px 0px',
        };

        this.debouncedOnProductView = debounceAggregate(products => {
            this.sendProductViewsToAnalytics(products, this.recommendationType);
        }, DEFAULT_DEBOUNCE_TIME);
    },

    methods: {
        async onLoadedProducts({ products }) {
            await this.loadProductsSliderComponent();
            this.productsLoaded = products;

            if (!products.length) {
                this.displayContainer = false;

                return;
            }

            this.emitRecommendationSliderViewEvent(products.length);
        },

        handleError() {
            this.displayContainer = false;
            this.isDisplayed = false;

            this.emitRecommendationSliderViewEvent(ERROR);
        },

        displayContent(intersect) {
            if (intersect) {
                this.isDisplayed = true;
            }
        },

        emitRecommendationSliderViewEvent(productsLength) {
            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_RECOMMENDATION_SLIDER_VIEW,
                {
                    sliderName: this.recommendationType,
                    offersCount: productsLength,
                }
            );
        },

        emitNavigationButtonClickEvent(direction) {
            if (this.recommendationType) {
                this.$analytics.moduleEmit(
                    PRODUCT_MODULE_NAME,
                    PRODUCT_RECOMMENDATION_SLIDER_ARROW_CLICK,
                    {
                        sliderName: this.recommendationType,
                        direction:
                            direction === 1
                                ? GLOBAL_LABELS.NAV_RIGHT
                                : GLOBAL_LABELS.NAV_LEFT,
                    }
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$title-height: $tailwindcss-spacing-7;
$title-height-md: 68px;
$scrollbar-height: $tailwindcss-spacing-4;
$slide-height: 396px;

@mixin products-slider-height($title-height, $scrollbar-height) {
    height: calc(#{$title-height} + #{$slide-height} + #{$scrollbar-height});
}

.slider-container {
    @apply w-full flex;

    @include products-slider-height($title-height, 0px);

    .recommended-slider-content {
        @apply px-0;
    }

    &.with-scrollbar {
        @include products-slider-height($title-height, $scrollbar-height);
    }

    @screen lg {
        @include products-slider-height($title-height-md, 0px);

        .recommended-slider-content {
            @apply px-5;
        }

        &.with-scrollbar {
            @include products-slider-height(
                $title-height-md,
                $scrollbar-height
            );
        }
    }
}
</style>
